import * as React from "react"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const NotFoundPage = ({data}) => {

  const imageData = data?.wp?.siteSettings?.siteSettings?.globalInnerHero

  // console.log("image", imageData)
  return (
  <DefaultLayout title={"Sorry, page not found..."}>
    <div className="rs-breadcrumbs sec-color">
        <GatsbyImage
          image={getImage(imageData?.backgroundImage?.localFile)}
          alt={"Saigon Raiders Club"}
          className="inner-img"
        />
        <div className="breadcrumbs-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="page-title">Error</h1>
                <ul>
                  <li>
                    <a className="active" href="/">
                      Home &#187;{" "}
                    </a>
                  </li>
                  <li> Error 404</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rs-club sec-spacer">
          <div className="container">
            <h3 className="title-bg">404: Not Found</h3>
            <div className="row justify-content-md-center">
              <div className="col-lg-6 col-md-12">
                <div className="rs-club-text">
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                </div>
              </div>
            </div>
          </div>  
      </div>
  </DefaultLayout>
  )
}

export default NotFoundPage

export const query = graphql`
query {
  wp {
    siteSettings {
      siteSettings {
        globalInnerHero {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, height: 380)
              }
            }
          }
        }
      }
    }
  }
}
`